// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-and-analytics-js": () => import("./../../../src/pages/data-and-analytics.js" /* webpackChunkName: "component---src-pages-data-and-analytics-js" */),
  "component---src-pages-engineering-js": () => import("./../../../src/pages/engineering.js" /* webpackChunkName: "component---src-pages-engineering-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-values-js": () => import("./../../../src/pages/our-values.js" /* webpackChunkName: "component---src-pages-our-values-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-page-p-js": () => import("./../../../src/pages/page-p.js" /* webpackChunkName: "component---src-pages-page-p-js" */),
  "component---src-pages-product-management-js": () => import("./../../../src/pages/product-management.js" /* webpackChunkName: "component---src-pages-product-management-js" */),
  "component---src-pages-startup-js": () => import("./../../../src/pages/startup.js" /* webpackChunkName: "component---src-pages-startup-js" */),
  "component---src-pages-startup-old-js": () => import("./../../../src/pages/startup-old.js" /* webpackChunkName: "component---src-pages-startup-old-js" */),
  "component---src-pages-success-stories-js": () => import("./../../../src/pages/success-stories.js" /* webpackChunkName: "component---src-pages-success-stories-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

